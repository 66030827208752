/*
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-05-26 14:43:07
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-05-27 16:53:54
 * @FilePath: \personal\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/index.vue'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: () => import('@/views/Home.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/ocr',
        component: () => import('@/views/ocr/index.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/text',
        component: () => import('@/views/text/index.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/edit',
        component: () => import('@/views/edit/index.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/pan',
        component: () => import('@/views/pan/index.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/down',
        component: () => import('@/views/down/index.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/recharge',
        component: () => import('@/views/recharge/index.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/news',
        component: () => import('@/views/news/index.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/news-detail',
        component: () => import('@/views/news/detail.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/help',
        component: () => import('@/views/news/help.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/personal',
        component: () => import('@/views/personal/index.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/ocrImg',
        component: () => import('@/views/ocr/ocrImg.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/ocr-compareImg',
        component: () => import('@/views/ocr/compareImg.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
      {
        path: '/organization',
        component: () => import('@/views/organization/index.vue'),
        meta: {
          title: '古籍智能整理平台',
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
