<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-06-05 17:57:07
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-07-12 22:28:38
 * @FilePath: \personal\src\components\AddOrEditModal.vue
 * @Description: 提示
-->
<template>
<div>
  <a-modal
    class="tips-modal"
    :width="360"
    :title="title || '提示'"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div v-if="tipsType == 'tipsNine' ||tipsType == 'tipsTen'" class="button">
        <div @click="handleOk">稍后查看</div>
      </div>
      <div v-else class="button">
        <div @click="handleClose" :class="{text: buttonText, 'tips-btn-cancle': !buttonText}" >{{ buttonText ? buttonText : '取消'}}</div>
        <div @click="handleOk" v-if="!buttonText">{{tipsType == 'tipsTwelve' ? '继续提交' : '确定'}}</div>
      </div>
    </template>
    <div class="modal-content" v-if="steps">
      <a-progress 
        :percent="percentNum"
        size="small"
        status="active"
        strokeColor="#6868CE"
      />
      <p style="margin-top: 80px;text-align: center;">正在智能处理中</p>
    </div>
    <div v-else class="modal-content">
      <div 
        v-for="(item, index) in tipsArr[tipsType]" :key="index"
        class="content"
      >
        <div v-html="item" :style="{
          'margin-top': 
            tipsType == 'tipsSeven' || tipsType == 'tipsFourteen'
              ? 0 : tipsType == 'tipsTwelve' || tipsType == 'tipsFifteen'  ? '10px' : '40px',
          'text-align': tipsType === 'tipsEight' || tipsType === 'tipsFour' 
          || tipsType === 'tipsNine' || tipsType === 'tipsTen' ? 'center' : 'left'
        }"/>
      </div>
    </div>
  </a-modal>
</div>
  
</template>

<script>
export default {
  props: [
    'visible',
    'title',
    'tipsType',
    'boxNum', // 选中了多少本书
    'picNum', // 删除书时，图片的数量
    'textNum', 
    'buttonText',
    'handleClose',
    'handleCallback',
    'percentNum', // 识别进度
    'steps', // 识别是否显示进度条
    'remainingData', // 识别重复数
    'layoutTypeText', // 选择了什么版式
    'fanJianStatus', // 繁转简 或简转繁
  ],
  data() {
    return {
      tipsArr: {
        tipsOne: ['删除后，将无法为您还原，您确定要删除吗？'],
        tipsTwo: [`共选中${this.boxNum}本书，涉及${this.picNum}个图片，${this.textNum}个文本，<br />删除后，将无法为您还原，您确定要删除吗？`],
        tipsThree: ['您确认图像校对已全部完成？','进入文本处理后，将无法在版面还原的基础上','进行校对，确定要进入文本处理吗？'],
        tipsFour: ['开始识别后将不可修改，确定开始吗？'],
        tipsFive: [`即将为您生成所选图书的${this.fanJianStatus == 1 ? '简' : '繁'}体版文件，确定开始吗？`],
        tipsSeven: [
          '（1）图片格式仅支持JPEG、 PNG、BMP、JPG格式;',
          '（2）图片单张大小建议在200KB以内;',
          '（3）最多容纳6张图片;'
        ],
        tipsEight: ['确定要退出登录吗？'],
        tipsNine: ['正在智能处理中...'],
        tipsTen: ['上传中，请稍候'],
        // tipsTen: [
        //   `本次版面为${this.layoutTypeText}类型，您选择了<b style="color: #6868CE;">${this.remainingData?.allCount}</b>页图像，
        //   ${this.remainingData?.repeatCount != 0 ? `<b style="color: #6868CE;">${this.remainingData?.repeatCount}</b>页重复识别不计，` : ''}
        //   ${this.remainingData?.currentConsumeAmount != 0 ? `共需<b style="color: #6868CE;">${this.remainingData?.currentConsumeAmount}</b>文，` : ''}
        //   开始识别后无法撤销。确定开始识别吗？`
        // ],
        tipsTwelve: ['您确认当前图书下所有图像校对已全部完成？<br />进入文本处理后，当前图书将无法在版面还原的基础上进行校对，确定要进入文本处理？'],
        tipsThirteen: ['将清除现有文档中的中文标点符号和专名线、书名线后进行标注，不可回退。确定开始吗？'],
        tipsFourteen: ['当前图书进入“文本处理（自动标点、自动标线、繁转简、简转繁）”或“在线编校”后，可重复进行文本处理和编校工作，但本书内所有页面将无法在版面还原的基础上（OCR图文校对页）进行校对，且无法增加新页面进行图像识别或调整页面顺序。确定要进入文本处理吗？'],
        tipsFifteen: [
          '1、可上传jpg、jpeg、png、tif、tiff 格式的图片，或pdf、djvu格式的文件。',
          '（1）建议上传的单张图像宽高均在1000像素以内，超出后系统将自动进行压缩。',
          '（2）若同一本书，上传图像名称重复，系统自动重命名。',
          '（3）如需要修改文件格式，请通过有效的文件转化工具，请勿仅通过“重命名”修改文件后缀名。修改后缀名与文件自身格式不一致会导致图片无法解析。',
          '2、网页端单次上限超出时，请您分批上传，或使用客户端以便上传更多图像。',
          '3、一本书中单书上限超出时，请您在【图书管理】页另外新建图书后再上传。',
          '4、建议您使用古联快传客户端上传，支持断点续传，更为方便。补传成功后，系统会根据本书内所有图像名称自动排序。',
          `5、目前仍有一些类型的图像，识别结果不尽如人意。详情请查阅<a href="${process.env.VUE_APP_BASE_PATH}help?type=OCR识别说明">OCR识别说明</a>。`
        ],
      },
      /** 单选框 */
      value: 1,
      agreeCheck: 0
    }
  },
  methods: {
    /** 提交 */
    handleOk() {
      this.handleCallback(this.value)
    },
  }
}
</script>

<style lang="less">
.tips-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        .tips-btn-cancle {
          margin-right: 50px;
        }

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #9B9B9B;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        div {
          &:nth-of-type(1):hover {
            border: 1px solid #6868CE;
            color: #6868CE;
          }
          &:nth-of-type(2):hover {
            opacity: 0.9;
          }
        }

        :nth-child(2) {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }

        .text {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff !important;
          border: none;
          &:hover {
            opacity: 0.8 !important;
            border: none !important;
          }
        }
      }
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 300px;
      overflow-y: auto;

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        // padding: 0 20px 0;

        // .modaldown {
        //   width: 100%;
        //   height: 100%;
        //   display: flex;
        //   flex-direction: column;
        // }

        .radio {
          width: 100%;
          height: 100px;
          display: flex;
          justify-content: space-between;
          margin-top: 26px !important;

          .ant-radio-group {
            width: 100%;
            display: flex;
            .ant-radio-wrapper:nth-of-type(2) {
              margin-left: 79px;
            }
          }
        }
      }
    }

    .tipsFlex {
      display: flex;
      justify-content: center;
    }
    .ant-progress > div {
      display: flex;
      justify-content: center;
    }
    .ant-progress-outer {
      width: 60%;
    }

    .ant-progress-inner {
      border: 1px solid #6868CE;
    }
    .ant-progress-bg {
      height: 14px !important;
    }
    .ant-progress-text {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }

    a {
      text-decoration: underline;
      color: #6868CE;
    }
  }
}

</style>