<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-06-05 17:57:07
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2023-09-03 00:38:42
 * @FilePath: \personal\src\components\TipsModal.vue
 * @Description: 提示 or 下载
-->
<template>
<div>
  <a-modal
    class="tips-box-modal"
    :width="360"
    :title="title || '提示'"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div class="button">
        <div @click="handleClose" v-if="!buttonText" class="cancle">取消</div>
        <div v-if="resultData && resultData.needAvailableCount" @click="handleOk" class="ok" style="margin-left: 20px;">充值</div>
        <div v-else @click="handleClose" class="ok">{{ buttonText ?  buttonText : '确定'}}</div>
      </div>
    </template>

    <div class="modal-content">
      <div 
        v-for="(item, index) in tipsArr[tipsType]" :key="index"
        :class="{modaldown: tipsType === 'tipsSix' }"
        class="content"
      >
        <div v-html="item" />
      </div>
    </div>
  </a-modal>
</div>

</template>

<script>
export default {
  props: [
    'visible',
    'title',
    'tipsType',
    'buttonText',
    'handleClose',
    'handleOk',
    'num',
    'resultData',
    'layoutTypeText',
    'userInfo',
    'cur',
    'beyond',
    'limitImageObj'
  ],
  data() {
    return {
      tipsArr: {
        tipsOne: ['请您阅读协议，并勾选”我同意本协议“'],
        tipsTwo: [`成功购买${this.num}页额度，请前往个人中心查看详情。`, '如有疑问，请查阅帮助中心，或联系我们。'],
        // tipsThree: [`受技术限制，每种图书最多可容纳600页图像，网页端每次最多仅可上传50页图像。本次选择图像${this.num}页，请您分批上传，或使用快传客户端以便上传更多图像。`],
        tipsFour: [`每种图书最多可容纳${this.limitImageObj?.maxBookStorageLimitToMB}MB图像，本次可再上传${this.limitImageObj?.thisBookMaxLimitToMB}MB图像，如需上传更多图像，请您在【图书管理】页另外新建图书后再上传。`],
        tipsFive: [`本次版面为${this.layoutTypeText}类型，您选择了<b style="color: #6868CE;">${this.resultData?.sumCount}</b>页图像，${this.resultData?.repeatCount != 0 ? `<b style="color: #6868CE;">${this.resultData?.repeatCount}</b>页重复识别不计，`: ''}共需<b style="color: #6868CE;">${this.resultData?.availablePage}</b>文，您的个人账户有<b style="color: #6868CE;">${this.userInfo?.availablePage}</b>文，仍需<b style="color: #6868CE;">${this.resultData?.needAvailableCount}</b>文，是否前往充值？`],
        tipsSix: [`成功购买${this.num}页额度，为保证您的消费权益，请重新选择图像，开始识别。`],
        tipsSeven: [`每次最多仅可上传${this.cur}MB文件。本次选择文件已超过${this.beyond}MB，请您适当拆分后再上传。`]
      }
    }
  },
  methods: {}
}
</script>

<style lang="less">
.tips-box-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      padding-bottom: 0;

      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #9B9B9B;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        .cancle:hover {
          border: 1px solid #6868CE;
          color: #6868CE;
        }

        .ok:hover {
          opacity: 0.9;
        }

        .ok {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }
      }
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 300px;
      overflow-y: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .content {
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #120808;
        line-height: 28px;
      }
    }
  }
}

</style>