<!--
 * @Description: 用户使用协议
-->
<template>
  <div>
    <a-modal
      class="register-modal"
      :width="1000"
      :title="title"
      :visible="visible"
      :maskClosable="false"
      @cancel="handleClose"
    >
      <template slot="footer">
        <div  class="button">
          <div @click="handleClose" class="tips-btn-cancle">取消</div>
          <div @click="handleOk">确定</div>
        </div>
      </template>
      
      <div class="modal-content">
        <h1>古联古籍智能整理平台</h1>
        <h1>用户使用服务协议</h1>
        <div class="content">
          <div 
            v-for="(item, index) in tipsArr[tipsType]" :key="index"
          >
            <p v-html="item"/>
          </div>
        </div>
        <div class="radio" >
          <a-radio-group v-model="agreeCheck">
            <a-radio :value="1" />
          </a-radio-group>
          <span>我已阅读并同意用户协议</span>
        </div>
        <div :style="{'color': 'red', 'margin-left': '24px',  'opacity': tipsVisible ? 1 : 0}">请勾选</div>
      </div>
    </a-modal>

    <!-- 提示 -->
    <TipsModal
      v-if="tipsVisible"
      :visible="tipsVisible"
      tipsType="tipsOne"
      buttonText="我知道了"
      :handleClose="tipsCallBack"
    />
  </div>
</template>

<script>
import TipsModal from '@/components/TipsModal.vue'

export default {
  components: { TipsModal },
  props: [
    'visible',
    'title',
    'tipsType',
    'buttonText',
    'handleClose',
    'handleCallback',
  ],
  data() {
    return {
      tipsArr: {
        tipsOne: [
          '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本协议由使用古联古籍智能整理平台服务的主体（包括但不限于个人、团队等）（以下简称“用户”）和古联古籍智能整理平台（以下简称“本系统”）提供者古联（北京）数字传媒科技有限公司（以下简称“古联公司”）对古联古籍智能整理平台的使用及相关服务所订立的有效合约，具有合同效力。<b>请您在确认同意本协议之前或在使用古联古籍智能整理平台服务之前认真阅读本协议。</b>',
          '<b>一、定义和说明</b>',
          '<b>1.1【本系统帐号】</b>',
          '您如果需要使用和享受本系统服务，则您需要将您享有合法使用权的<b>籍合网（www.ancientbooks.cn）帐号</b>作为本系统帐号，并在购买后享受跟购买额度相关的付费服务。本条款下又称“您”或“用户”。',
          '<b>1.2【本系统服务】</b>',
          '古联古籍智能整理平台服务，即用户可在古联古籍智能整理平台内享用的权益及服务，具体内容以古联古籍智能整理平台付费充值页面、OCR功能使用页面、文本处理页面、个人中心描述为准。',
          '<b>1.2.1【条款修改】</b>',
          '<b>古联公司有权随时对服务条款进行修改（修改包含变更、增加、减少相应的条款内容），一旦服务条款发生修改，古联公司将在相关页面进行更新展示。如果您不同意本条款的修改，可以停止对本服务的使用。您继续使用古联公司提供的服务，则视为您已经接受本条款的全部修改。</b>',
          '<b>1.3【本协议】</b>',
          '<b>1.3.1 本协议视为《籍合网用户规范使用服务协议》、“充值权益介绍”、“OCR可用额度及消费说明”、“服务期限及数据保管”的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。</b>',
          '<b>1.3.2 本协议内容同时包括古联公司可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分。同样对双方生效。</b>',
          '<b>二、帐号管理及使用规则</b>',
          '<b>2.1 【帐号管理】</b>',
          '2.1.1 您应正确使用及妥善保管、维护您的本系统帐号及密码，如发生任何泄漏、遗失、被盗等行为，而该等行为并非古联公司法定过错导致，所有损失将由您自行承担；',
          '2.1.2 您在此授予古联公司保护您帐号安全的相关权利，古联公司可定期或不定期采用不同的方式对您帐号的使用安全进行检查、验证，包括但不限于主动联系您进行身份验证、短消息验证、邮箱认证及密码修改等。如您无法完成验证或无正当理由拒绝验证的，古联公司可合理怀疑您的帐号出现异常或被盗，并向该帐号中止提供服务或采取进一步措施。',
          '<b>2.2 【使用规则】</b>',
          '<b>2.2.1【合法获取及使用】</b>',
          '(1) 古联古籍智能整理平台提供的相关服务 ，均仅限于您在古联古籍智能整理平台（www.collation.ancientbooks.cn）平台内使用，任何以破解密钥、转译、转录、模拟用户端调用后台接口等非法手段将古联古籍智能整理平台提供的服务与古联古籍智能整理平台分离的行为，均不属于本条款中约定的古联古籍智能整理平台用户服务。古联公司保留追究侵权人的法律责任及索赔的权利。',
          '(2) 任何以盗窃、利用系统漏洞、通过任何非古联公司官方或授权途径获得的古联古籍智能整理平台用户可用额度及相关服务（包括但不限于购买、租用、借用、分享、受让等方式获得）、恶意利用或破坏古联古籍智能整理平台营销活动获得的用户服务，均不获相关保护，古联公司有权取消及/或不予提供用户服务，所有产生的损失及责任由行为人自行承担',
          '(3) 使用古联古籍智能整理平台用户服务及/或参加相关活动时，亦应通过古联古籍智能整理平台官方公布/授权的方式进行，<b>用户通过其他任何渠道、任何途径、任何方式非法获取的古联古籍智能整理平台用户服务（包括且不限于帐号、付费权益、充值兑换码等）或参加活动获取福利（包括但不限于获得实体/虚拟礼品、付费权益、充值兑换码等），均不获保护，且一经发现古联公司有权立即就相关服务做出删除、取消、清零等处理，或采取其他如中止或终止对您提供服务等帐号管理措施，所有产生的损失及责任由行为人自行承担。</b>',
          '<b>2.2.2 【专有及非商业目的使用】</b>',
          '(1) 古联古籍智能整理平台用户服务，是在您遵守本条款及相关法律法规的前提下，古联公司给予您一项个人的、不可转让及非排他性的许可。',
          '<b>(2) 您理解并同意，您不得将享有用户服务的”籍合网帐号”以任何方式提供给他人使用，包括但不限于不得以转让、出租、借用、分享、出售等方式提供给他人使用。否则，因此产生任何法律后果及责任均由您自行承担，且古联公司有权中止或终止对您提供服务。</b>',
          '<b>2.2.3【帐号独立】</b>',
          '(1）同一主体拥有多个“籍合网帐号”并进行籍合网账号登录的，每一个“籍合网帐号”将被识别为一个独立的帐号。',
          '<b>(2) 每个帐号之间的购买记录、文档数据无法进行转移、迁徙、转让、赠与、售卖、租借、分享，无论该等帐号是否由同一使用人拥有，请您在登录及/或购买/参加活动时注意区分，避免造成损失。古联公司会视情况采取中止或终止对您提供服务，由此造成的损失由会员自行承担。</b>',
          '<b>2.2.4 【登录限制】</b>',
          '<b>您理解并同意，在开通本服务后，同一个帐号最多可以在五个浏览器上免验证使用，且同一时间内同一帐号最多在一个浏览器上使用。超过上述范围使用的，会顶替第一个浏览器免验证占位。</b>',
          '<b>2.2.5 【帐号找回】</b>',
          '<b>您登录古联古籍智能整理平台时使用的”籍合网帐号”信息遗失、忘记密码，导致无法登录古联古籍智能整理平台的，您如需找回的，需填写手机或邮箱，获取验证码，并确保提供的信息合法真实有效，若提供的材料不真实或不符合要求，无法通过古联公司的身份验证，可能会导致帐号找回失败，相应的风险和损失将由您自行承担。您理解古联公司对您的请求采取行动及处理均需要合理时间，除古联公司存在法定过错外，古联公司对您的损失不承担任何责任。</b>',
          '<b>三、服务内容</b>',
          '<b>3.1 【用户权益】</b>',
          '3.1.1 在您开通用户服务后，根据您的购买记录及您参加的相关活动，将会享有对应服务体验，具体以相关服务页面公布、实际提供的内容为准。',
          '3.1.2 您在购买古联古籍智能整理平台付费服务后可享受的全部权益以本系统公布的权益为准，古联公司有权基于自身业务发展需要变更全部或部分用户权益。就前述权益调整本系统将在相应服务页面进行更新展示。同时我们也鼓励您定期查看本协议内容，以更好地保障您的权益。',
          '<b>3.2【知识产权归属】</b>',
          '3.2.1古联古籍智能整理平台（www. collation.ancientbooks.cn）的知识产权归古联公司所有。',
          '<b>3.2.2用户在本系统上传的文件，知识产权归原权利人所有。</b>',
          '<b>3.2.3用户在本系统生产的数据，知识产权归用户或与用户协商的第三方所有，古联公司不主张拥有此项权利。</b>',
          '<b>3.3 【虚拟产品】</b>',
          '<b>基于虚拟产品的性质和特征，您所获取的虚拟产品（包括古联古籍智能整理平台“可用额度”，以及衍生的数据保管服务等）不能够进行退货、换货、兑换现金，亦不得用于商业领域，如买卖、置换、抵押等。一切通过非官方公布渠道取得的虚拟产品及其衍生服务均不获保护，古联公司有权中止或终止对您的服务；由此造成的损失由您自行承担。</b>',
          '<b>3.4 【设备及系统差异】</b>',
          '<b>您通过古联公司指定渠道开通本服务后，可能会由于您使用的软件版本、设备、操作系统等不同以及其他第三方原因等导致您实际可使用的具体服务有差别，由此可能给您带来的不便，您表示理解，您不予追究或者豁免古联公司的相关责任。</b>',
          '<b>3.5 【关于收费】</b>',
          '<b>本服务是古联公司提供的收费服务，您须在按照本服务的收费标准支付相应费用后，方可使用本服务。此外，您可能也可以通过接受系统赠送等古联公司认可的其他方式享有、使用本服务。 古联公司可能会根据本服务的整体规划，对本服务相关权益细则、收费标准、方式等进行修改和变更，前述修改、变更，古联公司将在相应服务页面进行更新展示。古联公司会根据实际状况，对不同阶段购买可用额度的用户给予不同的优惠，具体优惠政策以古联公司在相关服务页面公示的信息为准。提请您注意，充值到账及扣费标准更新可能会存在延时，以实际支付时的充值条款和扣费标准为准。同时我们也鼓励您定期查看本协议内容，以更好地保障您的权益。您在此理解并同意因参加活动不同，用户将享受不同的增值服务或资费。</b>',
          '<b>3.6 【支付】</b>',
          '3.6.1 您应该通过古联公司指定的包括但不限于支付宝支付、微信支付等现有方式或今后古联公司指定方式，依约支付。支付完成后，不可进行转让或退款。',
          '3.6.2 您不得通过以下任何方式为自己或他人开通本服务：',
          '(1) 以营利、经营等非个人使用的目的为自己或他人开通本服务；',
          '(2）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通本服务；',
          '(3）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；',
          '(4) 通过非古联公司指定的方式为自己或他人开通本服务；',
          '(5) 通过侵犯古联公司或他人合法权益的方式为自己或他人开通本服务；',
          '(6) 通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务；',
          '<b>3.7 【服务期限】</b>',

          '3.7.1 可用额度期限。',

          '(1)充值可用额度使用期限为永久。',

          '(2)初次访问平台，被赠予的300文可用额度，长期有效。',

          '3.7.2数据保管期限。',

          '(1)数据安全须知。',

              '<i></i>数据保存及访问。您理解并允许本系统在服务器上保存您上传的图像数据，并且由于技术服务等必要原因，允许本系统访问您上传的数据。',

              '<i></i>数据删除。您理解并允许本系统在服务完成后对您的数据进行删除。',

          '(2)文件存储期限。所上传的图像和文件，系统将为您保留一定期限。',

              '<i></i>已上传图像未识别的图书，自该种图书内图像的最晚上传之日起，该图书文件夹及全部图像在平台将保留3天，您可在此期间随时进行识别。如超过3天仍未发起OCR识别，系统将自动清理这些文件。',

              '<i></i>已进行OCR识别的图书，自该种图书文件夹内图像成功识别的最晚之日起，该图书图像及识别、校对产生的文本信息将在平台保留1年，您可在此期间随时登录平台查看、校对、下载文件，1年之后，该图书及其下图像、文本将全部删除。',

              '<i></i>已上传文本文件的图书，自该种图书上传之日起，该图书文件夹及全部文件在平台将保留3天，您可在此期间随时进行智能文本处理（自动标点、标线、繁简转换）。如超过3天仍未发起智能处理，系统将自动清理这些文件。 ',

              '<i></i>已进行智能文本处理的图书，自该种图书文本处理之日起，该图书文件及校对产生的文本信息将在平台保留1年，您可在此期间随时登录平台查看、校对、下载文件，1年之后，该图书及其下文本将全部删除。',

              '<i></i>在清理数据之前，平台将提前1天发送系统消息提醒。如因您未及时处理而造成的数据损失，由您自身负责。',

          '3.7.3 您理解、知悉并同意，本服务的服务期间，包含古联公司解决故障、服务器维修、调整、升级、因第三方侵权处理等所需的合理时间，对上述情况所需用的时间不另行补偿；',

          '<b>3.8【使用规则及说明】</b>',

          '为了向您提供更好的服务，古联古籍智能整理平台可能会对用户权益进行调整，包括但不限于：',

          '1.用户权益具体内容的调整',

          '2.用户付款方式、价格等的变更',

          '<b>四、违约责任</b>',

          '4.1.1 您在使用本服务的过程中，不得进行以下违约行为：',

          '4.1.2 违反法律、法规、规章、条例以及任何具有法律效力之规范规定的；',

          '4.1.3 破坏、影响古联公司对任何第三方提供本服务；',

          '4.1.4 进行危害计算机网络安全的行为；',

          '<b>4.1.5 对古联公司及关联第三方（包括授权方和合作方）提供的本条款服务、活动造成不良影响，侵犯及/或关联第三方古联公司及/或其他用户的正当利益；</b>',

          '4.1.6 被有权机构认定侵犯任何第三方的合法权益的；',

          '4.1.7 利用本服务获取非法利益的，包括但不限于通过转售、转让、转授权等行为不正当牟利；',

          '4.1.8 其他侵犯古联公司合法利益的行为；',

          '<b>4.2 【违约行为处理】</b>',

          '您确认并同意，在发现违约行为后，古联公司有权采取以下一项或多项措施处理：',

          '(1) 采取技术手段予以删除、屏蔽或断开相关的信息；',

          '(2) 采取包括但不限于中止或终止部分或全部本服务；',

          '(3) 采取扣除违约行为发生时间内的用户权益；',

          '(4) 采取限制用户使用平台功能等权益；',

          '(5) 古联公司无需向您退还任何费用，您的相关用户权益的损失由您自行承担，造成古联公司损失的（包括但不限于律师费、诉讼费等），您也应予以赔偿；',

          '<b>4.3 【赔偿责任】</b>',

          '<b>如用户的违约行为或违法行为使古联公司及其关联公司遭受损失，包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失，被告应当赔偿古联公司及其关联公司的上述全部损失，您的违约行为导致任何第三方损害的，您应当独立承担责任。</b>',

          '<b>五、服务的变更、中止及终止</b>',

          '5.1 本服务的中止或终止包含如下情况：',

          '用户主动中止或终止；',

          '因为用户违约行为，古联公司主动中止或终止服务的；',

          '因国家或相关部门要求或发生不可抗力事件时，古联公司中止或终止服务的；',

          '其他根据法律法规或古联公司业务调整等应当或者需要中止或终止服务的；',

          '5.2 中止或终止服务后，当您发现无法登录或享受服务时，可以咨询我们的在线客服。',

          '5.3 中止或终止后的处理:',

          '5.3.1 除法律规定的责任外，古联公司对本服务的中止或终止对用户和任何第三人不承担任何责任；',

          '<b>5.3.2 已收取的费用原则上不予退还；</b>',

          '5.3.3 正在进行的平台交易，古联公司将根据情况合理判断，是否继续进行或终止相关交易，并视情况决定是否进行退费或扣除相关费用作为违约金；',

          '5.4 古联古籍智能整理平台依据本协议的约定变更服务内容、服务方式以及本协议内容的，如果用户不同意古联古籍智能整理平台的变更，有权选择取消并停止使用已经获取的对应的全部或部分服务；如果用户继续使用古联古籍智能整理平台提供的上述服务，则视为会员已经接受古联古籍智能整理平台的上述调整。',

          '5.5 会员如果不同意条款的修改，可主动向古联古籍智能整理平台提出终止用户服务，但古联古籍智能整理平台不退还已使用的费用；如果会员继续享用用户服务，则视为会员已经接受条款的修改。',

          '5.6 您同意，由于互联网服务的特殊性，古联公司有权基于合法理由，修改、变更、中断或终止部分或全部服务，或变更、删除、转移用户存储在古联古籍智能整理平台的内容，统称“变更行为”。古联公司保留采取前述变更行为而不需通知您的权利，且您同意，依本服务条款任何规定提供之本服务，无需进行事先通知即可变更、中断或终止。并且您同意，由于变更行为而造成的任何损失，古联公司无需对您对或任何第三人承担任何责任。',

          '<b>六、免责声明</b>',

          '6.1 您知悉并同意，用户在古联古籍智能整理平台上传、生产的内容，为用户的个人行为，古联公司不对该等信息和内容的准确性、真实性、可用性、安全性、完整性和正当性承担责任，您在此同意豁免追加古联公司的相关责任。',

          '6.2 您在进行相关信息及内容的使用、分享、下载等行为（“使用行为”）时，应进行独立判断并合法使用，因您的使用行为造成任何自身损害及对第三方的损害，古联公司不承担任何责任。',

          '6.3 因电信和网通部门的通讯线路故障、网络或电脑故障、系统不稳定、不可抗力（如服务器当机）等非古联公司原因造成您帐号、帐号内可用额度、工作成果等财产丢失、减少的，您无权要求古联公司承担补偿等责任。',

          '6.4 您在使用古联古籍智能整理平台所提供的服务时，如遭受任何人身或财务的损失、损害或伤害，除法律有明确规定外，古联公司不承担任何责任。由于您将个人密码告知他人或与他人共享注册帐户，由此导致的任何个人资料泄露，古联公司不承担任何责任。',

          '<b>七、通知</b>',

          '本协议项下古联公司对于用户所有的通知均可通过网页公告、手机短信或常规的信件传送等方式进行，该等通知于发送之日视为已送达用户。 为便于您收取相关通知，您应确保预留的联系方式为正确及有效，并在变更后及时登录古联古籍智能整理平台修改，如因您未提供正确的联系信息或未及时告知新的联系方式，导致无法及时收取通知，将由您自行承担由此产生的损失及法律后果。 古联公司可通过上述方式之一或其中若干向您送达各类通知，而此类通知的内容或将对您产生重大有利或不利影响，请您务必确保联系方式为有效并请及时关注。',

          '<b>八、法律的适用和管辖<b>',

          '8.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。',

          '8.2 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向古联公司所在地北京市丰台区人民法院提起诉讼。',

          '8.3 古联公司未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。',

          '8.4 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。',

          '8.5 在法律法规允许的范围内，古联公司有权对本协议进行解释。'
        ],
      },
      agreeCheck: 0,
      tipsVisible: false
    }
  },
  methods: {
    /** 提交 */
    handleOk() {
      if (!this.agreeCheck) {
        this.tipsVisible = true
      } else {
        this.handleCallback(this.agreeCheck)
      }
    },
    tipsCallBack() {
      this.tipsVisible = false
    }
  }
}
</script>

<style lang="less">
.register-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 140px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        .tips-btn-cancle {
          margin-right: 50px;
        }

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #9B9B9B;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        div {
          &:nth-of-type(1):hover {
            border: 1px solid #6868CE;
            color: #6868CE;
          }
          &:nth-of-type(2):hover {
            opacity: 0.9;
          }
        }

        :nth-child(2) {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }

        .text {
          background-color: #1890ff ;
          color: #fff;
          border: 1px solid #1890ff;
          &:hover {
            opacity: 0.8 !important;
            color: #fff !important;
            border: none !important;
          }
        }
      }
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 400px;
      overflow-y: auto;

      h1 {
        text-align: center;
      }

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-top: 20px;

        i {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #999;
          display: inline-block;
          margin-right: 8px;
          margin-left: 10px;
        }
      }

      .radio {
        margin-top: 6px !important;
      }
    }
  }
}

</style>