import { getToken } from '@/utils/auth'
import axios from 'axios'
import { message } from 'ant-design-vue'
/**
 * Download file according to file address
 * @param {*} Url
 */
let temp = true

export function downloadByUrl({
  url,
  target = '_blank',
  fileName,
  requestUrl,
}) {
  // 禁止快速重复点击
  if (temp != true) return false
  temp = false

  const isChrome =
    window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1
  const isSafari =
    window.navigator.userAgent.toLowerCase().indexOf('safari') > -1

  if (/(iP)/g.test(window.navigator.userAgent)) {
    console.error('Your browser does not support download!')
    return false
  }

  url = process.env.VUE_APP_BASE_API + url
  axios({
    url: process.env.VUE_APP_BASE_API + requestUrl,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  })
    .then(function (response) {
      temp = true
      // 全部未识别
      if (response.data.code == 500) {
        message.error({
          content: response.data.msg,
          duration: 5,
        })

        return false
      }

      // 识别完成和未识别的
      if (response.data.code == 402) {
        message.error({
          content: response.data.msg,
          duration: 5,
        })
      }

      message.success(
        response.data.code == 402
          ? '剩余文件正在下载请耐心等候'
          : '文件正在下载请耐心等候'
      )

      if (isChrome || isSafari) {
        const link = document.createElement('a')
        link.href = url
        link.target = target

        if (link.download !== undefined) {
          link.download =
            fileName || url.substring(url.lastIndexOf('/') + 1, url.length)
        }

        if (document.createEvent) {
          const e = document.createEvent('MouseEvents')
          e.initEvent('click', true, true)
          link.dispatchEvent(e)
          return true
        }
      }
      if (url.indexOf('?') === -1) {
        url += '?download'
      }

      openWindow(url, { target })
      return true
    })
    .catch(function (error) {
      console.log(error)
      temp = true
      message.error('系统错误！')
      return false
    })
  return false
}

export function exportByUrl({
  url,
  target = '_blank',
  fileName,
}) {
  console.log('url', url)
  const isChrome =
    window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1
  const isSafari =
    window.navigator.userAgent.toLowerCase().indexOf('safari') > -1

  if (/(iP)/g.test(window.navigator.userAgent)) {
    console.error('Your browser does not support download!')
    return false
  }

  if (isChrome || isSafari) {
    const link = document.createElement('a')
    link.href = url
    link.target = target

    if (link.download !== undefined) {
      link.download =
        fileName || url.substring(url.lastIndexOf('/') + 1, url.length)
    }

    if (document.createEvent) {
      const e = document.createEvent('MouseEvents')
      e.initEvent('click', true, true)
      link.dispatchEvent(e)
      return true
    }
  }
  if (url.indexOf('?') === -1) {
    url += '?download'
  }
  openWindow(url, { target })
}

function openWindow(url, opt) {
  const { target = '__blank', noopener = true, noreferrer = true } = opt || {}
  const feature = []

  noopener && feature.push('noopener=yes')
  noreferrer && feature.push('noreferrer=yes')

  window.open(url, target, feature.join(','))
}
