<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-05-26 14:43:07
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-06-09 23:17:23
 * @FilePath: \personal\src\App.vue
 * @Description: 更改ant-design-vue 默认文案为中文
-->
<template>
 <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
      <iframe :src="path" v-if="loginFlag"></iframe>
      <a href="" target="_blank" id="openNewWindow"></a>
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import bus from '@/utils/bus';

export default {
  data() {
    return {
      locale: zhCN,
      path: process.env.VUE_APP_LOGIN_OUT,
      loginFlag: false
    }
  },
  created() {
    bus.$on('logout', (data) => {
      this.loginFlag = data
    })
  }
};
</script>

<style lang="less">
body {
  width: 100% !important;
  overflow: auto !important;
  padding: 0;
  margin: 0;

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    padding-top: 80px;
    height: 100vh;
    overflow-y: auto;
    min-width: 1440px;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .ant-message-notice-content {
    white-space:pre-wrap !important; 
    text-align: left;
  }

  // .ant-spin-nested-loading {
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  // }
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
    position: fixed;
    left: 0;
    top: 200px;
    right: 0;
    bottom: 0;
  }
}
</style>
