import request from '@/utils/request'

// 下载中心列表
export function getDownloadList(data) {
  return request({
    url: 'download/list',
    method: 'get',
    data,
  })
}

// 批量删除下载
export function downloadDelete(data) {
  return request({
    url: `download/${data.id}`,
    method: 'DELETE',
    data,
  })
}


// 客户端下载
export function getNewVesionList(data) {
  return request({
    url: `download/client/kuaiChuan/info`,
    method: 'POST',
    data,
  })
}

// 发票导出
export function rechargeExport(data) {
  return request({
    url: `personal/recharge/export`,
    method: 'GET',
    data,
  })
}


