<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-05-26 22:19:01
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-07-12 22:31:08
 * @FilePath: \personal\src\layout\header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="header-wrap">
      <div class="log" @click="goPath('/')"/>

      <div v-if="token" class="header-wrap-cont">
        <div class="header-wrap-left">
          <div class="operation">
            <div
              v-for="item in operationArr"
              :key="item.id"
              class="item"
              :class="{ active: checkedKey.indexOf(item.id) !== -1 }"
              @click="handleSwi(item.id)"
            >
              <img :src="checkedKey.indexOf(item.id) !== -1 ? item.img2 : item.img1" alt="" />
              <span> {{ item.title }} </span>
            </div>
          </div>
          
          <div class="news-box" @click="goPath('/news?date=' + new Date())" >
            <i v-if="noReadCount">{{noReadCount}}</i>
            <img src="../assets/lingdang-w.png" alt="" class="news-icon"/>
          </div>
          <div >
            <div class="user">
              <a-popover placement="bottom">
                <template slot="content">
                  <p class="header-user-list" @click="goPath('/personal')">
                    <img src="@/assets/login-user.png" />个人中心
                  </p>
                  <p v-if="userInfo.role == 'manager'" class="header-user-list" @click="goPath('/organization')">
                    <a-icon type="menu-fold" class="people-left-icon"/>
                    机构管理
                  </p>
                  <p v-if="userInfo.role == 'visit'" class="header-user-list" @click="institutionVisible = true">
                    <a-icon type="menu-fold" class="people-left-icon"/>
                    加入机构
                  </p>
                  <p class="header-user-list" @click="handleLogOut">
                    <img src="@/assets/login-logout.png" />退出登录
                  </p>
                </template>
              
                <img :src="`${imgPath}${userInfo.imgUrl}`" class="head-url"/>
              </a-popover>
              <div class="user-all">
                <a-tooltip :title="userInfo.role && userInfo.role != 'user' ? userInfo.nikeName + '+' + userInfo.institutionName : userInfo.nikeName" placement="topLeft">
                  <span class="oneHidden">{{userInfo.nikeName}}
                    <template v-if="userInfo.role && userInfo.role != 'user'" >+{{userInfo.institutionName}}</template>
                  </span>
                </a-tooltip>
               
                <span v-if=" userInfo.role == 'vip' || userInfo.role == 'default' || userInfo.role == 'manager'">到期时间：{{userInfo.expireDate}}</span>
              </div>
              <span class="availablePage-text" @click="goPath('/personal')">我的川资： {{userInfo.availablePage}}文</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <a :href="loginPath" class="login-text">登录</a>
        <a :href="registPath" class="login-text">注册</a>
      </div>
      
    </div>
    <Recharge v-if="this.rechargeFlag" @cancle="changeCancle"/>

    <!-- 退出登陆 -->
    <HintOrDownModal
      :visible="visible"
      title="提示"
      tipsType="tipsEight"
      :handleCallback="handleLoginOutCallback"
      :handleClose="handleClose"
    />

    <!-- 用户协议 -->
    <RegisterModal
      :visible="registerVisible"
      title="用户使用协议"
      tipsType="tipsOne"
      :handleCallback="handleRegisterCallback"
      :handleClose="handleRegisterClose"
    />

    <!-- 提示 -->
    <TipsModal
      v-if="tipsVisible"
      :visible="tipsVisible"
      tipsType="tipsTwo"
      buttonText="我知道了"
      :handleClose="tipsCallBack"
      :num="tipsNum"
    />

    <!-- 绑定手机号提示 -->
    <PhoneModal
      v-if="phoneVisible"
      :visible="phoneVisible"
      :handleClose="phoneCallBack"
    />

    <!-- 系统升级提示 -->
    <SystemModal  
      :visible="systemVisible"
      title="公告"
      tipsType="tipsOne"
      :handleClose="systemClose"
      :handleOk="systemOk"
    />

    <AddInstitutionModal
      v-if="institutionVisible"
      :visible="institutionVisible"
      :userInfo="userInfo"
      :handleClose="institutionClose"
    />
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { getToken , removeToken, setToken} from '@/utils/auth'
import ocr from "../assets/ocr-w.png";
import text from "../assets/text-w.png";
import edit from "../assets/edit-w.png";
import pan from "../assets/pan-w.png";
import down from "../assets/down-w.png";
import recharge from "../assets/recharge-w.png";

import ocr1 from "../assets/ocr.png";
import text1 from "../assets/text.png";
import edit1 from "../assets/edi.png";
import pan1 from "../assets/pan.png";
import down1 from "../assets/dow.png";
import recharge1 from "../assets/recharge.png";

import Recharge from '@/views/recharge/index.vue'
import { logout, getTimerCount, getUserAgreementAnnounce, getUserInfo } from '@/api/user'
import HintOrDownModal from '@/components/HintOrDownModal.vue'
import RegisterModal from '@/components/RegisterModal.vue'
import TipsModal from '@/components/TipsModal.vue'
import PhoneModal from '@/components/PhoneModal.vue'
import SystemModal from '@/components/SystemModal.vue'
import AddInstitutionModal from '@/components/AddInstitutionModal.vue'
import bus from '@/utils/bus';

//个人用户=user，【个人昵称】
//机构访客=visit，【个人昵称+机构名称】
//机构访客=vip，【个人昵称+机构名称，显示到期日期】
//机构会员=default，【个人昵称+机构名称，显示到期日期】
//机构管户=manager，【个人昵称+机构名称，显示到期日期】
export default {
  components: { Recharge, HintOrDownModal, RegisterModal,
    TipsModal, PhoneModal, SystemModal, AddInstitutionModal
  },
  data() {
    return {
      operationArr: [
        {
          title: "OCR识别",
          img1: ocr,
          img2: ocr1,
          id: "/ocr",
        },
        {
          title: "文本处理",
          img1: text,
          img2: text1,
          id: "/text",
        },
        {
          title: "在线编校",
          img1: edit,
          img2: edit1,
          id: "/edit",
        },
        {
          title: "工作总览",
          img1: pan,
          img2: pan1,
          id: "/pan",
        },
        {
          title: "下载中心",
          img1: down,
          img2: down1,
          id: "/down",
        },
        {
          title: "充值",
          img1: recharge,
          img2: recharge1,
          id: "/recharge",
        },
      ],
      checkedKey: "/ocr",
      loginPath: '',
      registPath: process.env.VUE_APP_REGIST_URL,
      imgPath: process.env.VUE_APP_IMG_PATH,
      rechargeFlag: false,
      userInfo: {},
      token: getToken(),
      visible: false,
      noReadCount: 0,
      registerVisible: false,
      tipsVisible: false,
      tipsNum: 0,
      phoneVisible: false,
      phoneLoading: false,
      systemVisible: false,
      institutionVisible: false,
    };
  },
  watch: {
    $route () {
      this.checkedKey = this.$router.history.current.path
      if (!getToken()) return 
      this.initPhone()
    }
  },
  mounted() {
    this.loginPath = process.env.VUE_APP_LOGIN_PATH
    this.checkedKey = this.$route.path
    this.userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {}

    this.setSystemShow()

    if (getToken())  {
      this.initPhone()

      setInterval(async() => {
        const res = await getTimerCount()
        this.userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {}
        this.userInfo.availablePage = res.data.availablePage
        this.userInfo.userBuy = res.data.userBuy
        this.noReadCount = res.data.msgCount

        bus.$emit('feedBackCount', res.data)

        if (!this.phoneLoading) {
          if (!this.userInfo.phone && !this.userInfo.userBuy) {
            this.phoneVisible = true
          } else {
            this.phoneVisible = false
          }
        }

        // 判断机构是否更改角色或更换机构
        if (this.userInfo.institutionId != res.data.institutionId || this.userInfo.role != res.data.institutionRole) {
          const ret = await getUserInfo({
            isLogin: false,
          })

          this.userInfo = ret.data
          setToken(ret.data.token)
          Cookies.set('userInfo', JSON.stringify(ret.data))

          return
        }
       
        Cookies.set('userInfo', JSON.stringify(this.userInfo))
        
      }, 5000)
    }

    bus.$on('institutionAdd', () => {
      this.institutionVisible = true
    })
  },
  methods: {
    initPhone() {
      if (this.userInfo.phone && !this.userInfo.loginAgreement) {
        this.registerVisible = true
      } else {
        this.registerVisible = false
      }

      // 手机号不存在需绑定
      if (!this.userInfo.phone && !this.userInfo.userBuy) {
        this.phoneVisible = true
      } else {
        this.phoneVisible = false
      }
    },
    systemClose() {
      this.systemVisible = false
    },
    systemOk() {
      this.systemVisible = false
      Cookies.set('systemFlag', true)
    },
    setSystemShow() {
      let endData = new Date('2023-5-23 18:00:00').getTime()
      let nowData = new Date().getTime()
      if (nowData < endData && !Cookies.get('systemFlag')) {
        this.systemVisible = true
      } else {
        this.systemVisible = false
      }
    },
    // 导航切换
    goPath(path) {
      this.checkedKey = ''
      this.$router.push(path)
      this.rechargeFlag = false;
    },
    // 用户注册协议确定
    async handleRegisterCallback() {
      this.registerVisible = false
      await getUserAgreementAnnounce({})
      this.userInfo.loginAgreement = true
      Cookies.set('userInfo', JSON.stringify(this.userInfo))
    },
    // 用户注册协议取消
    handleRegisterClose() {
      this.registerVisible = false
      this.handleLoginOutCallback()
    },
    // 充值显示
    handleSwi(id) {
      if (id == '/recharge') {
        this.rechargeFlag = true;
        return
      }
      this.rechargeFlag = false;
      this.checkedKey = id;
      this.$router.push(`${id}`)
    },
    // 充值弹窗关闭
    changeCancle(num) {
      this.rechargeFlag = false;

      if (num) {
        this.tipsNum = num
        this.tipsVisible = true
      }
    },
    // 退出登录确定回调
    async handleLoginOutCallback() {
      this.visible = false;
      Cookies.remove('WXTOKEN')
      Cookies.remove('userInfo')
      removeToken()
      await logout()
      window.location.href = process.env.VUE_APP_LOGIN_OUT + '?blackUrl=' +encodeURIComponent(`${process.env.VUE_APP_BASE_PATH}${this.$router.history.current.path.substr(1)}`)
    },
    // 退出登陆弹窗显示
    handleLogOut() {
      this.visible = true;
    },
    // 退出登陆关闭
    handleClose() {
      this.visible = false;
    },
    // 关闭提示弹窗
    tipsCallBack() {
      this.tipsVisible = false
    },
    // 绑定手机号成功重新获取用户信息
    async phoneCallBack() {
      this.phoneVisible = false
      this.phoneLoading = true
      const res = await getUserInfo({
        isLogin: false,
      })

      if (res.data) {
        setToken(res.data.token)
        this.userInfo = res.data
        Cookies.set('userInfo', JSON.stringify(res.data))

        if (this.userInfo.phone && !this.userInfo.loginAgreement) {
          this.registerVisible = true
        } else {
          this.registerVisible = false
        }

        this.phoneLoading = false
      }
    },
    institutionClose() {
      this.institutionVisible = false
    }
  },
};
</script>
<style lang="less" scoped>
.header-wrap {
  width: 100%;
  height: 80px;
  padding: 0 50px;
  background: #6868ce;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  .log {
    flex: 245px 0 0;
    width: 245px;
    height: 51px;
    background: url(../assets/logo.png) no-repeat;
    background-size: cover;
    cursor: pointer;
  }

  .operation {
    width: 535px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 42px;

    .item {
      width: 84px;
      padding: 12px 0;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        letter-spacing: 1px;
      }
    }

    .active {
      background: #f7d03e;

      span {
        color: #000;
      }

      img {
        color: #000;
      }
    }
  }

  img {
    width: 24px;
    height: 24px;
  }

  .user {
    margin-left: 70px;
    // width: 200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-url {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
    }

    span {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      letter-spacing: 1px;
      cursor: pointer;
    }

    .oneHidden{
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; 
    }

    .user-all {
      max-width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 10px;
    }

    .availablePage-text {
      margin-left: 20px;
    }
  }

  .login-text {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 1px;
    margin: 0 25px;
  }

  .header-wrap-left {
    display: flex;
    align-items: center;
  }

  .news-box {
    position: relative;
    i {
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      font-size: 14px;
      border-radius: 50%;
      background: red;
      color: #fff;
      position: absolute;
      top: -15px;
      left: 15px;
      font-style: normal;
    }
    .news-icon {
      cursor: pointer;
    }
  }
}
</style>

<style lang="less">
.notification-tips-box {
  button {
    margin-right: 10px;
  }
}

.header-user-list {
  margin-bottom: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  i {
    font-size: 20px;
    margin-right: 10px;
    color: #6868ce;
  }
}
</style>
