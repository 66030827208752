<template>
  <div class="recharge-content">
    <div class="mask">
      <div class="mask-content">
        <a-icon type="close" class="close" @click="handleCancle"/>
        <div class="mask-title"><img src="../../assets/icon-rechange.png"/><span>充值</span></div>
        <div class="page-num">您的剩余川资：{{userInfo.availablePage}}文</div>
        <p>快捷选择：</p>
        <div class="page-money" style="margin-bottom: 20px;">
          <span
            v-for="(item, key) in pageMoney"
            :key="key"
            :class="[{ 'active': pageMoneyType == key}]"
            @click.stop="changeMoney(key)"
          >{{key}}<i>元 | {{item}}文</i></span>
        </div>

        <p v-if="!pageData">自定义充值：</p>
        <p v-if="!pageData" style="padding-left: 100px;color: #000;">
          <a-input
            v-model="inputPageMoney"
            placeholder="请输入阿拉伯数字"
            style="width: 200px;margin-right: 10px;"
            @input="inputChangeMoney"
            @focus="inputMoneyFocus"
            ref="input"
          />元

          <span v-if="isValid">
            <a-icon @click.stop="inputMoney" type="check-circle" theme="filled" :style="{fontSize: '30px', color:  '#6868CE', 'vertical-align': 'middle', 'cursor': 'pointer', 'position': 'relative', 'z-index': 10}" />
          </span>
          <a-icon v-else type="check-circle" theme="filled" :style="{fontSize: '30px', color: '#CCCCCC', 'vertical-align': 'middle'}" />
        </p>

        <p>充值说明：<span style="padding-left: 24px;">1.风险提示：您购买的额度，不能够进行退货、换货、兑换现金。</span></p>
        <p style="padding-left: 102px;">2.发票说明：如需开具发票，请到个人中心的 <b style="cursor: pointer;" @click="jumpHelp">&nbsp;充值记录&nbsp;</b>中进行开票。</p>

        <template v-if="orderObj.qrCode">
          <p>支付方式：
            <span class="wx-chat">
              <img src="@/assets/icon-wxchat.png"/>
              微信支付
            </span>
            <span v-if="!changeFlag" style="color:#808080">
              （最多可OCR<b class="blue">&nbsp;{{ pageMoneyType ? Math.floor((pageMoneyType / 1.5)) : Math.floor((inputPageMoney / 1.5))}}&nbsp;</b>页图像，或智能整理<b class="blue">&nbsp;{{ pageMoneyType ? Number((pageMoneyType /1.5).toFixed(2)) : Math.floor((inputPageMoney / 1.5) * 100) / 100}}&nbsp;</b>千字文本。可自由组合使用）
            </span>
          </p>

          <div class="qrCode">
            <div class="qrCode-cont">
              <img :src="orderObj.qrCode"/>
              <p>扫码支付<span style="color:#808080">（使用微信扫码完成支付）</span>
                <b v-if="!changeFlag"><i style="font-size: 20px;font-style: normal;">¥</i>{{pageMoneyType || inputPageMoney}}</b>
              </p>
            </div>
            <p style="text-align: left;margin-left: 80px;">如二维码已过期，请
              <span @click="handlePayAgain">刷新二维码</span>
            </p>
          </div>
        </template>

        <p>服务介绍：</p>
        <div class="tips-content">
          <p>古籍智能整理平台，将为用户提供如下服务：</p>
          <p>1)        古籍图像识别及校对。优秀的智能OCR算法及图文校对工具，满足用户从古籍影像中提取、加工高质量文本的需求。</p>
          <p>2)        文本处理。为用户提供高效、便捷的智能文本处理工具，包括古籍文本的自动标点、自动标线、古籍繁体文本自动转简体。</p>
          <p>3)        文本编辑。在线编辑器集成众多文本处理工具，支持对古籍文本进行标点、分段、校勘、注释等古籍整理工作所需的文本编辑操作。</p>
          <p>4)        文件下载。支持在任意阶段以书为单位导出文件进行离线处理，也支持线上一站式完成古籍整理工作。</p>
        </div>

        <div class="mask-btn">
          <span class="button-cancle" @click="handleCancle">取消</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { createOrder, orderCheck } from '@/api/user'
import bus from '@/utils/bus'

export default {
  props: [
    'pageData'
  ],
  data() {
    return {
      timer: null,
      userInfo: {},
      pageMoney: {
        60: 60,
        600: 600,
        900: 900
      },
      pageMoneyType: 60,
      orderObj: {
        qrCode: ''
      }, // 订单信息
      checkOne: false,
      inputPageMoney: '',
      changeFlag: false,
      pageMoneyFlag: false,
      isValid: false
    };
  },
  created() {
    this.userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {}

    if (this.pageData) {
      this.pageMoney = this.pageData

      this.pageMoneyType = Object.keys(this.pageData)[0]
    }

    this.handlePay()
  },
  methods: {
    inputChangeMoney(e) {
      this.pageMoneyType = ''
      this.pageMoneyFlag = false
      this.changeFlag = true

      this.inputPageMoney = this.inputPageMoney.replace(/\D/g, '');
      this.inputPageMoney = this.inputPageMoney.slice(0, 7)
      if (this.inputPageMoney < 1 ) {
        this.inputPageMoney = '';
      }

      if (this.inputPageMoney) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },
    // 输入框获取焦点
    inputMoneyFocus() {
      this.pageMoneyType = ''
      this.pageMoneyFlag = false
      this.changeFlag = true
      this.orderObj.qrCode = ''
    },
    // 手动输入金额
    inputMoney() {
      console.log('222')
      if (this.checkOne) return this.$message.warn('正在加载中，请稍候再试')
      this.pageMoneyType = ''
      if (!this.inputPageMoney || this.inputPageMoney.length > 7) {
        this.inputPageMoney = ''
        this.pageMoneyFlag = true
        this.orderObj.qrCode = ''
        
        clearInterval(this.timer)
        this.$refs.input.blur()
        return
      } else {
        this.pageMoneyFlag = false
      }
      
      this.changeFlag = false
      this.handlePayAgain('input')
    },
    changeMoney(key) {
      if (this.checkOne) return this.$message.warn('正在加载中，请稍候再试')
      this.pageMoneyType = key

      this.changeFlag = false
      this.inputPageMoney = ''
      this.isValid = false

      this.handlePayAgain()
    },
    handleCancle() {
      this.checkOne = false
      this.orderObj.qrCode = ''
      clearInterval(this.timer)
      this.$emit('cancle')
    },
    // 重新刷新二维码
    handlePayAgain(type) {
      if (this.checkOne) return

      this.checkOne = true

      this.orderObj.qrCode = ''
      clearInterval(this.timer)

      this.handlePay(type)
    },
    // 生成订单
    async handlePay(type) {
      const hide = this.$message.loading('正在生成订单，请稍候', 0);
      
      const res = await createOrder({
        pageCount: type == 'input' ? this.inputPageMoney : this.pageMoney[this.pageMoneyType]
      })

      hide()
      this.orderObj = res.data
      this.checkOne = false
      this.getOrderOk()
    },
    // 轮询查询订单状态
    getOrderOk() {
      this.timer = setInterval(async () => {

        this.checkOne = false
        if (!this.orderObj.outTradeNo) {
          clearInterval(this.timer)
        }

        const res = await orderCheck({
          orderCode: this.orderObj.outTradeNo
        })

        if (res.data == 1) {
          clearInterval(this.timer)
          bus.$emit('newsCountRefresh')
          this.orderObj = {
            qrCode: ''
          }
          this.checkOne = false

          this.$emit('cancle', this.pageMoney[this.pageMoneyType])
        } else if (res.data == 2) {
          this.checkOne = false
          this.$message.error('支付异常')
        }
      }, 2000)
    },
    // 跳转发票说明
    jumpHelp() {
      this.handleCancle()
      this.$router.push('/personal')
    }
  },
};
</script>

<style lang="less">
.recharge-content {
  z-index: 100;

  .mask {
    width: 100%;
    height: calc(100vh - 80px);
    position: fixed;
    left: 0;
    right: 0;
    top: 80px;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 10;
  }

  .mask-content {
    width: 862px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 0 20px 27px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    position: fixed;
    left: 50%;
    top: 50px;
    margin-left: -431px;
    .close {
      position: absolute;
      right: 30px;
      top: 20px;
    }
    .mask-title {
      width: 80px;
      font-size: 16px;
      font-weight: 500;
      color: #6868CE;
      border-bottom: 5px #6868CE solid;
      margin: 15px auto;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 17px;
        height: 20px;
        margin-right: 8px;
      }
    }

    .page-num {
      font-size: 14px;
      font-weight: 600;
      color: #6868CE;
      padding: 14px 20px;
      border-bottom: 1px solid rgba(104, 104, 206, 0.23);
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #6868CE;
      padding: 6px;
      text-align: left;
    }

    .page-money {
      display: flex;
      justify-content: center;
      span {
        width: 100px;
        height: 34px;
        display: inline-block;
        cursor: pointer;
        color: #fff;
        line-height: 34px;
        font-size: 18px;
        background: #99BFFF;
        margin-right: 10px;
        i {
          font-style: normal;
          font-size: 14px;
        }
        &:nth-of-type(2),
        &:nth-of-type(3) {
          width: 120px;
        }
        &:nth-of-type(3) {
          margin-right: 0;
        }
        &.active{
          background: linear-gradient(114deg, rgba(29, 50, 206, 0.77) 0%, rgba(66, 16, 198, 0.67) 100%);
        }
      }
    }

    .money-tips-content {
      text-align: center;
      margin: 10px 0 20px;
    }

    .pinkColor {
      color: #6868CE;
      font-weight: bold;
    }

    .tips-content {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      border: 1px solid #E6E6E6;
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #6868CE;
      text-align: left;
      padding: 10px;
      margin-bottom: 16px;
      p {
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        &:nth-of-type(1) {
          font-weight: bold;
        }
      }
    }

    .wx-chat {
      border-radius: 8px;
      display: inline;
      padding: 4px 10px;
      color:#000;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .mask-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px dashed #C6C4C4;
      padding-top: 25px;
      margin-top: 10px;
    }
  }

  .qrCode {
    min-height: 240px;
    p {
      text-align: center;
      span {
        cursor: pointer;
      }
    }

    .qrCode-cont {
      height: 200px;
      position: relative;
      text-align: left;
      margin-left: 86px;
      p {
        position: absolute;
        left: 200px;
        top: 50px;
        color: #000;
        b {
          display: block;
          font-size: 30px;
          margin-top: 10px;
          text-align: left;
          color: #6868CE;
        }
      }
    }
  }

  .blue {
    color: #6868CE;
  }

  .ant-input-number-input {
    text-align: center;
  }
}

</style>
