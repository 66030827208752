<!--
 * @Description: 系统升级提示
-->
<template>
  <div>
    <a-modal class="system-modal" :width="600" :title="title" :visible="visible" :maskClosable="false"
      @cancel="handleModalClose">
      <template slot="footer">
        <div class="button">
          <div @click="handleModalClose" class="tips-btn-cancle">取消</div>
          <div @click="handleModalOk">不再提示</div>
        </div>
      </template>

      <div class="modal-content">
        <div class="content">
          <h2>尊敬的用户：</h2>
          <div v-for="(item, index) in tipsArr[tipsType]" :key="index">
            <p v-html="item" />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: [
    'visible',
    'title',
    'tipsType',
    'buttonText',
    'handleClose',
    'handleOk',
  ],
  data() {
    return {
      tipsArr: {
        tipsOne: [
          '为了能够给您持续提供安全、稳定的服务环境和功能体验，计划于2023年5月23日（星期二）11:00-18:00进行系统维护升级，届时本产品将暂停服务，请您提前规划使用需求。给您带来的不便，敬请谅解！感谢您的理解与支持，祝您生活愉快！',
          '【更新时间】2023年5月23日（星期二）11:00-18:00',
          '【更新方式】停服更新',
          '【更新范围】“籍合网”及下属所有数据库产品和在线服务'
        ],
      }
    }
  },
  methods: {
    /** 提交 */
    handleModalOk() {
      this.handleOk()
    },
    handleModalClose() {
      this.handleClose()
    }
  }
}
</script>

<style lang="less">
.system-modal {
  .ant-modal-body {
    padding-top: 0;
  }
  .ant-modal-content {
    .ant-modal-header {
      border: none;

      .ant-modal-title {
        width: 140px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        .tips-btn-cancle {
          margin-right: 50px;
        }

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #9B9B9B;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        div {
          &:nth-of-type(1):hover {
            border: 1px solid #6868CE;
            color: #6868CE;
          }

          &:nth-of-type(2):hover {
            opacity: 0.9;
          }
        }

        :nth-child(2) {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }

        .text {
          background-color: #1890ff;
          color: #fff;
          border: 1px solid #1890ff;

          &:hover {
            opacity: 0.8 !important;
            color: #fff !important;
            border: none !important;
          }
        }
      }
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 400px;
      overflow-y: auto;
      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        h2 {
          font-size: 14px;
          font-weight: normal;
        }

        p {
          text-indent: 28px;
        }
      }
    }
  }
}
</style>