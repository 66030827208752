<!--
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-05-26 23:51:36
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-05-26 23:57:31
 * @FilePath: \personal\src\layout\footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div
    class="footer-wrap" :style="{background: checkedKey ? '#fff' : '#c9d5e0', height: checkedKey ? '150px' : '108px'}">
    <div class="content">
      <div>
        <span @click="jumpDetail('/help')">使用帮助</span> | 
        <span @click="jumpDetail('/news-detail?type=about')">关于我们</span> | 
        <span @click="jumpDetail('/personal?type=feedback')">意见反馈</span> | 
        <span @click="clientVisible = true"><b>工具下载：</b>古联快传客户端</span>
      </div>
      <div v-if="checkedKey">国家古籍数字化工程专项经费资助项目</div>
      <span>
        中华书局有限公司&nbsp;古联（北京）数字传媒科技有限公司&nbsp;&nbsp;&nbsp;版权所有&nbsp;©2018-2023&nbsp;&nbsp;京ICP备16022726号-5
      </span>
    </div>
    <ClientDownModal v-if="clientVisible" :visible="clientVisible" :handleClose="handleClientClose"/>
  </div>
</template>
<script>
import ClientDownModal from '@/components/ClientDownModal.vue'
export default {
  components: {
    ClientDownModal,
  },
  data() {
    return {
      checkedKey: false,
      path: '',
      clientVisible: false
    }
  },
  watch: {
    $route: {
      handler() {
        this.checkedKey = this.$router.history.current.path == '/index' ? true : false
        this.path = this.$router.history.current.path
      },
      immediate: true
    },
  },
  methods: {
    jumpDetail(path) {
      this.$router.push(path)
    },
    handleClientClose() {
      this.clientVisible = false
    }
  }
};
</script>
<style lang="less">
.footer-wrap {
  width: 100%;
  height: 108px;
  background: #c9d5e0;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    height: 56px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    div {
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
