<template>
<div>
  <a-modal
    class="client-down-modal"
    :width="400"
    title="下载"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div  class="button">
        <div @click="handleClose" class="cancle">取消</div>
        <div @click="handleOk" class="ok">确定</div>
      </div>
    </template>
   
    <div class="modal-content">
      <div class="content">
        <div v-html="tipsContent" />
        <div class="radio">
          <a-radio-group v-model="value">
            <a-radio value="X64">
              <a-icon type="windows" theme="filled"/>
              Win64版
            </a-radio>
            <a-radio value="X32">
              <a-icon type="windows" theme="filled"/>
              Win32版
            </a-radio>
            <a-radio value="MACOS">
              <a-icon type="apple" theme="filled" />
              Mac版
            </a-radio>
          </a-radio-group>
        </div>

        <div class="version-box">
          {{ detailDate.versionNo }}正式版 | 更新日期：{{ detailDate.createTime }}<br />
          <template v-if="value == 'X64' || value == 'X32'">
            支持: Win10及以上
          </template>
          <template v-else>
            支持: MacOS 10.14及以上
          </template>
        </div>
      </div>
    </div>
  </a-modal>
</div>
  
</template>

<script>
import { getNewVesionList } from '@/api/download'
import { exportByUrl } from '@/utils/download'

export default {
  props: [
    'visible',
    'handleClose',
  ],
  data() {
    return {
      tipsContent: `请选择要下载的古联快传客户端安装版本：`,
      /** 单选框 */
      value: 'X64',
      detailDate: {}
    }
  },
  async mounted() {
    const res = await getNewVesionList({})
    this.detailDate = res.result
  },
  methods: {
    /** 提交 */
    handleOk() {
      exportByUrl({
        url: `${process.env.VUE_APP_GULIAN_API}setup/getUploadSteup?type=${this.value}`,
        target: '_self',
      }) 
      this.handleClose()
    },
  }
}
</script>

<style lang="less">
.client-down-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        
        div {
          width: 84px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        .cancle {
          border: 1px solid #9B9B9B;
          margin-right: 50px;
        }


        .ok {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }

        div {
          &:nth-of-type(1):hover {
            border: 1px solid #6868CE;
            color: #6868CE;
          }
          &:nth-of-type(2):hover {
            opacity: 0.9;
          }
        }
      }
    }

    .modal-content {
      width: 100%;
      min-height: 160px;
      max-height: 300px;
      overflow-y: auto;

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-top: 20px;

        .radio {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 20px 0;

          .ant-radio-group {
            width: 100%;
            display: flex;
            .ant-radio-wrapper {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              flex: 1 0 0;
              .ant-radio {
                margin-bottom: 6px;
              }
            }
            .anticon {
              display: block;
              font-size: 40px;
              background-color: #0000;
              margin-bottom: 10px;
            }
          }
        }

        .version-box {
          border-top: 1px dashed #ccc;
          padding: 10px 20px;
          text-align: center;
        }
      }
    }
  }
}

</style>