var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{staticClass:"institution-add",attrs:{"width":800,"title":"申请加入机构","visible":_vm.visible},on:{"cancel":_vm.handleClose}},[_c('template',{slot:"footer"},[_c('div',{staticClass:"button"},[_c('div',{on:{"click":_vm.handleClose}},[_vm._v("取消")]),_c('div',{attrs:{"html-type":"submit"},on:{"click":_vm.handleOk}},[_vm._v("确定")])])]),_c('div',{staticClass:"form-content"},[_c('a-form',{attrs:{"form":_vm.form,"layout":_vm.formLayout,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('div',{staticClass:"form-item"},[_c('a-form-item',{attrs:{"label":"机构名称"}},[_c('a-input',{attrs:{"disabled":true},model:{value:(_vm.userInfo.institutionName),callback:function ($$v) {_vm.$set(_vm.userInfo, "institutionName", $$v)},expression:"userInfo.institutionName"}})],1),_c('a-form-item',{attrs:{"label":"证件号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'userIdentity',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入证件号码',
                    },
                    {
                      validator: function (rule, val, callback) {
                        var reg = /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                        if (val && !reg.test(val)) {
                          callback('证件号码格式不正确');
                        } else {
                          callback();
                        }
                      }
                    }
                  ],
                  validateTrigger: 'blur'
                } ]),expression:"[\n                'userIdentity',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入证件号码',\n                    },\n                    {\n                      validator: (rule, val, callback) => {\n                        const reg = /^[1-9]\\d{5}(19|20)\\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$/\n                        if (val && !reg.test(val)) {\n                          callback('证件号码格式不正确');\n                        } else {\n                          callback();\n                        }\n                      }\n                    }\n                  ],\n                  validateTrigger: 'blur'\n                },\n              ]"}],attrs:{"placeholder":"请输入","maxLength":20}})],1)],1),_c('div',{staticClass:"form-item"},[_c('a-form-item',{attrs:{"label":"用户姓名："}},[_c('a-input',{attrs:{"maxLength":20,"disabled":true},model:{value:(_vm.userInfo.nikeName),callback:function ($$v) {_vm.$set(_vm.userInfo, "nikeName", $$v)},expression:"userInfo.nikeName"}})],1),_c('a-form-item',{attrs:{"label":"职业："}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['userWork']),expression:"['userWork']"}],attrs:{"getPopupContainer":function (triggerNode) {
                  return triggerNode.parentNode || _vm.document.body;
                },"placeholder":"请选择","options":_vm.userWorkOptions},model:{value:(_vm.userWork),callback:function ($$v) {_vm.userWork=$$v},expression:"userWork"}})],1)],1),_c('div',{staticClass:"form-item"},[_c('a-form-item',{attrs:{"label":"手机号："}},[_c('a-input',{attrs:{"maxLength":11,"disabled":true},model:{value:(_vm.userInfo.phone),callback:function ($$v) {_vm.$set(_vm.userInfo, "phone", $$v)},expression:"userInfo.phone"}})],1),_c('a-form-item',{attrs:{"label":"申请用途："}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['applyPurpose', { rules: [{ required: true, message: '请输入申请用途' }] }]),expression:"['applyPurpose', { rules: [{ required: true, message: '请输入申请用途' }] }]"}],attrs:{"placeholder":"请输入","maxLength":50}})],1)],1),_c('div',{staticClass:"form-item",staticStyle:{"margin-top":"-20px"}},[_c('a-form-item',{attrs:{"label":"邮箱："}},[_c('a-input',{attrs:{"maxLength":50,"disabled":true},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)])],1),_c('div',{staticClass:"des-box"},[_c('h2',[_vm._v("机构会员权益：")]),_c('p',[_vm._v("1、享有使用该机构川资（即机构可用额度）的权利，具体权限以所在机构支持情况为准。")]),_c('p',[_vm._v("2、使用智能功能包含OCR识别、自动标点、自动标线、繁简转换时，可不消费个人额度而是使用机构额度。")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }