<template>
<div>
  <a-modal
    class="institution-add"
    :width="800"
    title="申请加入机构"
    :visible="visible"
    @cancel="handleClose"
  >
    <template slot="footer">
      <div class="button">
        <div @click="handleClose">取消</div>
        <div  @click="handleOk" html-type="submit">确定</div>
      </div>
    </template>
    <div class="form-content">
      <a-form :form="form" :layout="formLayout" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <div class="form-item">
          <a-form-item label="机构名称">
            <a-input
              v-model="userInfo.institutionName"
              :disabled="true"
            />
          </a-form-item>
          <a-form-item label="证件号码" >
            <a-input
              v-decorator="[
                'userIdentity',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入证件号码',
                    },
                    {
                      validator: (rule, val, callback) => {
                        const reg = /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                        if (val && !reg.test(val)) {
                          callback('证件号码格式不正确');
                        } else {
                          callback();
                        }
                      }
                    }
                  ],
                  validateTrigger: 'blur'
                },
              ]"
              placeholder="请输入"
              :maxLength="20"
            />
          </a-form-item>
        </div>
        <div class="form-item">
          <a-form-item label="用户姓名：" >
            <a-input
              v-model="userInfo.nikeName"
              :maxLength="20"
              :disabled="true"
            />
          </a-form-item>
          <a-form-item label="职业：" >
            <a-select
              :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode || document.body;
                }
              "
              v-decorator="['userWork']"
              v-model="userWork"
              placeholder="请选择"
              :options="userWorkOptions"
            >
            </a-select>
          </a-form-item>
        </div>
        <div class="form-item">
          <a-form-item label="手机号：" >
            <a-input
              v-model="userInfo.phone"
              :maxLength="11"
              :disabled="true"
            />
          </a-form-item>
          <a-form-item label="申请用途：" >
            <a-textarea
              v-decorator="['applyPurpose', { rules: [{ required: true, message: '请输入申请用途' }] }]"
              placeholder="请输入"
              :maxLength="50"
            />
          </a-form-item>
        </div>
        <div class="form-item" style="margin-top: -20px;">
          <a-form-item label="邮箱：" >
            <a-input
              v-model="email"
              :maxLength="50"
              :disabled="true"
            />
          </a-form-item>
        </div>
      </a-form>
    </div>
    <div class="des-box">
      <h2>机构会员权益：</h2>
      <p>1、享有使用该机构川资（即机构可用额度）的权利，具体权限以所在机构支持情况为准。</p>
      <p>2、使用智能功能包含OCR识别、自动标点、自动标线、繁简转换时，可不消费个人额度而是使用机构额度。</p>
    </div>
  </a-modal>
</div>
</template>

<script>
import { joinVip, getDictData } from '@/api/user'

export default {
  props: ['visible', 'handleClose', 'userInfo'],
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      formLayout: 'horizontal',
      checkedOne: false,
      email: '',
      userWorkOptions: [],
      checkOne: false
    }
  },
  async mounted() {
    this.email = this.userInfo.email
    const res = await getDictData({
      types: 'user_occupation'
    })

    this.userWorkOptions = res.data
  },
  methods: {
    /** 提交 */
    handleOk() {
     this.handleSubmit();
    },
    /** 确定 */
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          console.log('Received values of form: ', values);
        } else {
          if (this.checkOne) return
          this.checkOne = true
          await joinVip({
            ...values, 
            institutionId: this.userInfo.institutionId,
            email: this.email
          })
          this.checkOne = false
          this.handleClose()
        }
      });
    },
  },
}
</script>

<style lang="less">
.institution-add {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 140px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        div {
          width: 84px;
          height: 34px;
          border: 1px solid #9B9B9B;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        :nth-child(2) {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
          margin-left: 50px;
        }
      }
    }

    .form-content {
      width: 100%;
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;

      .form-item {
        display: flex;
        width: 660px;
        margin: 0 auto;
        .ant-row {
          width: 320px;
          &:nth-of-type(1) {
            margin-right: 20px;
          }
        }
      }

      .ant-form {
        width: 100%;
        height: 100%;
      }

      .ant-form-vertical {
        .ant-form-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          
        }
      }
      .book-name .ant-form-item-label {
        width: 245px;
        text-align: left;
      }
    }
  }

  .des-box {
    width: 660px;
    margin: 20px auto;
    h2 {
      font-size: 14px;
      margin-bottom: 10px;
    }
    p {
      line-height: 20px;
    }
  }
}
</style>