/*
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-06-08 20:24:49
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-06-26 22:10:38
 * @FilePath: \personal\src\api\user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

// 可用额度列表信息
export function getConsumeList(data) {
  return request({
    url: 'institution/consume/user',
    method: 'get',
    data,
  })
}

// 意见反馈
export function feedback(data) {
  return request({
    url: 'feedback/addFeedback',
    method: 'post',
    data,
  })
}

// 轮播图
export function getBannerList(data) {
  return request({
    url: 'home/bannerList',
    method: 'get',
    data,
  })
}

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: 'userInfo/login',
    method: 'POST',
    data,
    // type: 'login',
  })
}

// 本地获取用户信息
export function getUserInfoHost(data) {
  return request({
    url: 'userInfo/hostLogin',
    method: 'get',
    data,
    // type: 'login',
  })
}

// 退出本系统登录
export function logout(data) {
  return request({
    url: 'logout',
    method: 'get',
    data,
    // type: 'login',
  })
}

// // 获取页码
// export function getPage(data) {
//   return request({
//     url: 'ocr/getPage',
//     method: 'get',
//     data,
//   })
// }

// 获取全局提示
export function getTimerCount(data) {
  return request({
    url: 'home/timer/count',
    method: 'get',
    data,
  })
}

// 是否存在文本
export function hasXml(data) {
  return request({
    url: 'ocr/hasXml',
    method: 'get',
    data,
  })
}

// 创建订单接口
export function createOrder(data) {
  return request({
    url: 'weixin/createOrder/' + data.pageCount,
    method: 'post',
    data,
  })
}

// 订单状态查询
export function orderCheck(data) {
  return request({
    url: 'weixin/check',
    method: 'get',
    data,
  })
}

// 通知同意
export function getUserAgreementAnnounce(data) {
  return request({
    url: 'helpContent/userAgreementAnnounce',
    method: 'get',
    data,
  })
}

// 游客申请加入会员
export function joinVip(data) {
  return request({
    url: 'institution/user/join/vip',
    method: 'POST',
    data,
  })
}

// 充值记录-获取list
export function getRechargeList(data) {
  return request({
    url: 'personal/recharge/list',
    method: 'GET',
    data,
  })
}

// 充值记录-获取累计获取额度
export function getSumPageNum(data) {
  return request({
    url: 'personal/recharge/sumPageNum',
    method: 'GET',
    data,
  })
}

// 充值记录-申请开票-列表
export function getInvoiceList(data) {
  return request({
    url: 'personal/invoice/list',
    method: 'GET',
    data,
  })
}

// 充值记录-申请开票-获取最小开票时间
export function minInvoiceDate(data) {
  return request({
    url: 'personal/invoice/minInvoiceDate',
    method: 'GET',
    data,
  })
}

// // 充值记录-申请开票-提交发票信息
export function saveInvoice(data) {
  return request({
    url: 'personal/invoice/save',
    method: 'POST',
    data,
  })
}

// 获取发票信息历史记录
export function getInvoiceTemplate(data) {
  return request({
    url: 'personal/invoice/back/template',
    method: 'GET',
    data,
  })
}

// 使用记录-获取使用功能
export function getConsumeFunctions(data) {
  return request({
    url: 'consume/functions',
    method: 'GET',
    data,
  })
}

// 使用记录-获取累积多少字
export function getConsumeTextCount(data) {
  return request({
    url: 'consume/textCount',
    method: 'GET',
    data,
  })
}

// 用户回复列表
export function getListAnswer(data) {
  return request({
    url: 'feedback/listAnswer',
    method: 'GET',
    data,
  })
}

// 继续反馈内容
export function feedbackAnswer(data) {
  return request({
    url: 'feedback/answer',
    method: 'POST',
    data,
  })
}


// 字典类型
export function getDictData(data) {
  return request({
    url: 'dict/data',
    method: 'GET',
    data,
  })
}

// 意见反馈-新增tab页（查看回复)
export function getFeedbackTree(data) {
  return request({
    url: 'feedback/tree',
    method: 'GET',
    data,
  })
}






