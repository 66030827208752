/*
 * @Author: wb_guochuang wb_guochuang@kuaishou.com
 * @Date: 2022-05-26 14:43:07
 * @LastEditors: wb_guochuang wb_guochuang@kuaishou.com
 * @LastEditTime: 2022-05-29 16:33:30
 * @FilePath: \personal\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import 'babel-polyfill'
import Cookies from 'js-cookie'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import('@/styles/index.less')
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import { getUserInfo, getUserInfoHost } from '@/api/user'
import { getToken, setToken } from '@/utils/auth'
import { notification } from 'ant-design-vue'
import bus from '@/utils/bus';

Vue.config.productionTip = false
Vue.use(Antd)

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title
  if (to.query.islogin || !getToken()) {
    let res = {}
    if (process.env.NODE_ENV == 'development') {
      res = await getUserInfoHost({
        username: 15010958957,
        password: 123456,
      })

      res.data.role = 'visit'
      res.data.institutionName = '古联（北京）数字传媒科技有限'
      res.data.expireDate = '2023年3月20日'
      res.data.institutionExpireDate = '2023年10月20日'
      
    } else {
      res = await getUserInfo({
        isLogin: to.query.islogin ? true : false,
      })
    }

    if (res.data) {
      setToken(res.data.token)
      Cookies.set('userInfo', JSON.stringify(res.data))

      if (res.data.role === 'visit') {
        notification.info({
          placement: 'bottomRight',
          message: '系统消息',
          duration: null,
          description:
            `您已进入《${res.data.institutionName}》机构范围内，成为机构会员，将享受机构更多福利。`,
            btn: h => {
              return h('div', {class: 'notification-tips-box'}, [h(
                'a-button',
                {
                  props: {
                    size: 'small',
                    style: 'marginRight: 10px;'
                  },
                  on: {
                    click: () => notification.close(1),
                  },
                },
                '取消',
              ),
              h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      bus.$emit('institutionAdd')
                      notification.close(1)
                    },
                  },
                },
                '加入机构',
              )])
            },
            key: 1,
            onClose: () => {
              notification.close(1)
              console.log('close')
            },
        });
      }
    }
  }
  next()
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
