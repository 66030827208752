import axios from 'axios'
import {
  message
} from 'ant-design-vue'
import {
  getToken,
  removeToken
} from '@/utils/auth'
import router from './../router'
import bus from '@/utils/bus'
import Cookies from 'js-cookie'
import {
  setTimeout
} from 'core-js'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 0,
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers.post['Content-Type'] = 'application/json'

    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
      config.headers['userTokenKey'] = JSON.parse(
        Cookies.get('userInfo')
      ).userTokenKey
    }

    // get请求参数拼接
    if (config.method == 'get') {
      config.params = config.data
    }

    // 古联前缀兼容
    if (config.path) {
      config.baseURL = config.path
    }

    return config
  },
  (error) => {
    // do something with request error
    console.log('【请求异常】',error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use((response) => {
    const res = response.data
    if (res.code == 200 || res.code == 489) {
      return res
    } else {
      if (res.code === 401) {
        bus.$emit('logout', true)
        setTimeout(() => {
          bus.$emit('logout', false)
          removeToken()
          Cookies.remove('WXTOKEN')
          Cookies.remove('userInfo')
          location.href = process.env.VUE_APP_LOGIN_PATH
        }, 2000)
        return
      } else if (res.code === 499) {
        removeToken()
        Cookies.remove('WXTOKEN')
        Cookies.remove('userInfo')
        location.href = process.env.VUE_APP_LOGIN_PATH
        return
      } else if (res.code === 699) {
        removeToken()
        Cookies.remove('WXTOKEN')
        Cookies.remove('userInfo')
        let original=window.location.origin;
        if(original.indexOf("testcollation")){
          location.href = original+"/client-user/index";
        }else{
          location.href = original+"/index";
        }
      } else if (res.code === 490) {
        document.getElementById('openNewWindow').href = res.data
        document.getElementById('openNewWindow').click() //点击事件
        return
      } else if (res.code === 470) {
        message.error(res.msg || '系统正在升级维护中，请稍候尝试!')
        setTimeout(() => {
          removeToken()
          Cookies.remove('WXTOKEN')
          Cookies.remove('userInfo')
          location.href = process.env.VUE_APP_LOGIN_PATH
        }, 3000)
        return
      }
      message.error(res.msg || '系统正在升级维护中，请稍候尝试!')
      return Promise.reject(
        new Error(res.message || '系统正在升级维护中，请稍候尝试!')
      )
    }
  },(error) => {
    console.log('响应异常】',error)
    message.error(error.msg || '系统正在升级维护中，请稍候尝试!')
    return Promise.reject(error)
  }
)

export default service