<!-- 绑定手机号 -->
<template>
<div>
  <a-modal
    class="add-phone-modal"
    :width="560"
    title="绑定手机号"
    :visible="visible"
    :maskClosable="false"
    @cancel="handleClose"
    :footer="null"
  >
    <iframe :src="phoneUrl"></iframe>
  </a-modal>
</div>
  
</template>

<script>
export default {
  props: [
    'visible',
    'handleClose',
  ],
  data() {
    return {
      phoneUrl: process.env.VUE_APP_ADD_PHONE
    }
  },
  mounted() {
    window.addEventListener('message', (event) => {
      console.log('event', event)
      if (event.data == '绑定完成') {
        this.$message.success('绑定成功');
        this.handleClose()
      }
    });
  },
}
</script>

<style lang="less">
.add-phone-modal {
  .ant-modal-content {
    .ant-modal-header {
      border:  none;
      .ant-modal-title {
        width: 80px;
        height: 32px;
        border-bottom: 5px solid #6868CE;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        color: #6868CE;
      }
    }

    .ant-modal-footer {
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 46px;

        
        div {
          width: 84px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #9B9B9B;
          cursor: pointer;
        }

        .cancle {
          border: 1px solid #9B9B9B;
          margin-right: 50px;
        }


        .ok {
          background: url(../assets/beijing.png) no-repeat;
          background-size: cover;
          color: #fff;
          border: none;
        }

        div {
          &:nth-of-type(1):hover {
            border: 1px solid #6868CE;
            color: #6868CE;
          }
          &:nth-of-type(2):hover {
            opacity: 0.9;
          }
        }
      }
    }

    iframe {
      width: 100%;
      height: 250px;
      overflow-y: auto;
      border: none;
    }
  }
}

</style>